import { useEffect, useState } from "react";
import "react-treeview/react-treeview.css";
import { Link, useLocation } from "react-router-dom";
import { BrushOutlined, CategoryOutlined, CodeOutlined, ContentCopyOutlined, ForwardToInboxOutlined, HeadsetMicOutlined, InboxOutlined, NotificationsOutlined, PeopleOutlined, PersonOutlineOutlined, PublicOutlined, ScheduleOutlined, SettingsOutlined, SettingsSuggestOutlined, SmartToyOutlined, SystemUpdateAltOutlined, TimerOutlined } from "@mui/icons-material";
import { useAgent } from "@/Hooks/useAgent";
import { ImportsModal } from "../../Components/Utility/Modals/ImportsModal";

export default function ConfigTreeView() {
	const isAdmin = useAgent().isAdmin ?? false;
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="py-3 px-2">
			<Link to="/config"><div className="text-base hover:bg-gray-200 active:bg-gray-300 px-1 py-0.5 flex items-center rounded"><SettingsOutlined fontSize="small" /> <div className="ml-1">Config</div></div></Link>

			<div className="ml-1">
				<div className="mt-3 mb-1 font-semibold">Manage</div>

				<TreeLink url="/config/manage/agents" label="Agents" icon={<PersonOutlineOutlined fontSize="small" />} />
				<TreeLink url="/config/manage/cannedreplies" label="Canned Replies" icon={<ContentCopyOutlined fontSize="small" />} />
				<TreeLink url="/config/manage/categories" label="Categories" icon={<CategoryOutlined fontSize="small" />} />
				<TreeLink url="/config/manage/slas" label="SLAs" icon={<TimerOutlined fontSize="small" />} />
				<TreeLink url="/config/manage/teams" label="Teams" icon={<PeopleOutlined fontSize="small" />} />
				<TreeLink url="/config/notifications" label="Notifications" icon={<NotificationsOutlined fontSize="small" />} />

				{isAdmin &&
					<>
						<div className="mt-3 mb-1 font-semibold">Email</div>
						<TreeLink url="/config/manage/channels/email" label="Mailboxes" icon={<InboxOutlined fontSize="small" />} />
						<TreeLink url="/config/email/notifications" label="Automated Emails" icon={<ForwardToInboxOutlined fontSize="small" />} />

						<div className="mt-3 mb-1 font-semibold">Settings</div>
						<TreeLink url="/config/helpdesk" label="Help Desk" icon={<HeadsetMicOutlined fontSize="small" />} />
						<TreeLink url="/config/branding" label="Branding" icon={<BrushOutlined fontSize="small" />} />
						<TreeLink url="/config/cname" label="CNAME" icon={<PublicOutlined fontSize="small" />} />
						{/* <TreeLink url="/config/manage/account" label="Account" icon={<MdAccountCircle/>} />*/}
						{/* <TreeLink url="/config/manage/billing" label="Billing" icon={<FaMoneyBillWave/>} />*/}
						<TreeLink url="/config/automations" label="Automations" icon={<SettingsSuggestOutlined fontSize="small" />} />
						<TreeLink url="/config/workday" label="Working Hours" icon={<ScheduleOutlined fontSize="small" />} />

						<TreeLink onClick={() => setIsOpen(true)} label="Imports" icon={<SystemUpdateAltOutlined fontSize="small" />} />
						<TreeLink onClick={() => window.open("/api-docs", "_blank")} label="Api Docs" icon={<CodeOutlined fontSize="small" />} />

						<div className="mt-3 mb-1 font-semibold">AI Tools</div>
						<TreeLink url="/config/ai/assistant" label="Knowledge Base assistant" icon={<SmartToyOutlined fontSize="small" />} />
					</>}
			</div>

			<ImportsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
}

interface TreeLinkProps {
	url?: string;
	onClick?(): void;
	label: string;
	icon: JSX.Element;
}

function TreeLink(props: TreeLinkProps) {
	const [active, setActive] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
		setActive(props.url != null && location.pathname.includes(props.url));
	}, [location, props.url]);

	const activeStyles = active ? " bg-gray-200 " : "";

	const button = <div onClick={props.onClick} className={"hover:bg-gray-200 active:bg-gray-300 py-1 px-3 rounded flex items-center cursor-pointer " + activeStyles}>
		{props.icon}
		<div className="ml-2">
			{props.label}
		</div>
	</div>;

	return (
		props.url != null ?
			<Link to={props.url} className="block">
				{button}
			</Link>
			:
			button
	);
}
