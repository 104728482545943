import { EventSourceParserStream } from "eventsource-parser/stream";
import { getAccessToken } from "@/legacy/ApiAuth";
import { getApiUrl } from "@/Api/Api";
import { Action } from "@shared/Entities/EntityTypes";

/**
 * Asks the BE to tell GPT to write us a KB article draft from an action.
 * The result will be streamed back to us as it is generated.
 * @param onResponse Will be called everytime we have a new bit of text from GPT.
 * INCLUDES '\n' CHARACTERS WHICH NEED TO BE HANDLED.
 * @param onStart Called when the stream starts.
 * @param onComplete Called when there is no more text to be recieved.
 */
export async function draftKbArticle(action: Action, onResponse: (data: string) => void, onStart: () => void, onComplete: () => void) {
	const token = await getAccessToken();
	const apiUrl = getApiUrl();

	const options: RequestInit = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + token
		},

		body: JSON.stringify(action)
	};

	await fetch(`${apiUrl}/gpt/draft-kb-article-from-action`, options)
		.then(async (response) => {
			if (response.body == null) {
				throw "Could not get body";
			}

			onStart();

			// Read responses as strings.
			const reader = response.body
				.pipeThrough(new TextDecoderStream())
				.pipeThrough(new EventSourceParserStream())
				.getReader();

			while (true) {
				const { value, done } = await reader.read();

				if (done) {
					break;
				}

				onResponse(value.data);
			}
		});

	onComplete();
}
